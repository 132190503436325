<template>
  <el-drawer
    title="Console"
    :visible.sync="$store.state.showDeveloperConsole"
    :modal="true"
    :wrapperClosable="true"
    size="100%"
    direction="ltr"
  >
    <div slot="title">
      <h3>Console</h3>
      <el-cascader
        style="padding-left: 16px"
        size="small"
        v-model="selectedOptions"
        :options="options"
        placeholder="Select Action"
        @change="handleChange"
      />
    </div>

    <el-container>
      <el-main>
        <el-tabs v-model="activeTab" lazy @tab-click="handleTabClick">
          <el-tab-pane label="Status" name="status" :lazy="true">
            <vue-json-pretty :data="status" />
            <router-link to="/superadmin">
              <el-button size="small" type="primary"> Bot JSON </el-button>
            </router-link>
            <Billing />
          </el-tab-pane>
          <el-tab-pane label="Bot Snapshots" name="botSnapshots" :lazy="true">
            <el-table
              size="mini"
              :data="tempBotSnapshots"
              @selection-change="handleSelectionChange"
            >
              <el-table-column type="selection" min-width="55" />
              <el-table-column prop="name" label="Name" min-width="190" />
              <el-table-column prop="createdAt" label="Updated At" min-width="180" />
            </el-table>
            <el-pagination
              layout="total, sizes, prev, pager, next"
              :page-sizes="[5, 10, 20, 50, 100]"
              :page-size="pageSize"
              :current-page="currentPage"
              :total="noOfItems"
              @prev-click="togglePage"
              @next-click="togglePage"
              @current-change="togglePage"
              @size-change="handleSizeChange"
            />
            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: start;
                justify-content: start;
                margin-top: 20px;
              "
            >
              <el-button
                v-loading="loading && !!botMultipleSelection.length"
                @click="handleDeleteBotsClick"
                :disabled="!botMultipleSelection.length"
                style="margin-right: 5px"
                size="mini"
                >Delete Selected Bots</el-button
              >
              <el-input-number
                v-model="botsToDeleteCount"
                style="margin-right: 5px"
                :min="1"
                :max="100"
                size="mini"
                :disabled="!!botMultipleSelection.length"
              />
              <el-button
                v-loading="loading && !botMultipleSelection.length"
                size="mini"
                @click="handleDeleteBotsClick"
                :disabled="!!botMultipleSelection.length"
                >Delete Last {{ botsToDeleteCount }} Bots</el-button
              >
            </div>

            <p>
              "Delete Last n Bots" will delete the oldest n number of bots. It will retain bots from
              the last 30 days, and 1 bot a day from the 31st day onwards.
            </p>
            <p>
              **Please do bot deletion during low traffic times as it consumes a lot of database
              server resources. Try deleting 1 - 5 oldest bots before doing more to estimate the
              time it will take.
            </p>
          </el-tab-pane>
          <el-tab-pane label="FAQ Snapshots" name="faqSnapshots" :lazy="true">
            <FaqSnapshots />
          </el-tab-pane>
          <el-tab-pane label="Livechat Resolver" name="livechatResolver" :lazy="true">
            <JSONEditor ref="jsonEditor" v-model="livechatResolverPayload" />
          </el-tab-pane>
          <el-tab-pane label="Livechat Debugger" name="livechatDebugger" :lazy="true">
            <LivechatDebugger />
          </el-tab-pane>
          <el-tab-pane label="Protected Keys" name="protectedKeys" :lazy="true">
            <ProtectedKeys />
          </el-tab-pane>
          <el-tab-pane label="System Variables" name="systemVariables" :lazy="true">
            <SystemVariables />
          </el-tab-pane>
          <el-tab-pane label="PII Redactor" name="piiRedaction" :lazy="true">
            <PiiRedactor />
          </el-tab-pane>
          <el-tab-pane label="GenAI" name="genai" :lazy="true">
            <GenAI />
          </el-tab-pane>
          <el-tab-pane label="Clean Visitor Data" name="cleanVisitorData" :lazy="true">
            <JSONEditor ref="jsonEditor" v-model="cleanVisitorDataPayload" />
          </el-tab-pane>
        </el-tabs>
      </el-main>
    </el-container>
  </el-drawer>
</template>

<script>
import Billing from "./DeveloperConsole/Billing";
import VueJsonPretty from "vue-json-pretty";
import _ from "lodash";
import JSONEditor from "@/components/JSONEditor";
import FaqSnapshots from "@/components/DeveloperConsole/FaqSnapshots.vue";
import SystemVariables from "@/components/DeveloperConsole/SystemVariables.vue";
import PiiRedactor from "@/components/DeveloperConsole/PiiRedactor.vue";
import LivechatDebugger from "@/components/DeveloperConsole/LivechatDebugger.vue";
import ProtectedKeys from "@/components/DeveloperConsole/ProtectedKeys.vue";
import GenAI from "@/components/DeveloperConsole/GenAI.vue";

export default {
  components: {
    VueJsonPretty,
    Billing,
    JSONEditor,
    FaqSnapshots,
    SystemVariables,
    PiiRedactor,
    LivechatDebugger,
    ProtectedKeys,
    GenAI
  },
  data() {
    return {
      activeTab: "status",
      currentPage: 1, // Used to track the current page in pagination
      selectedOptions: "",
      tempBotSnapshots: [],
      botMultipleSelection: [],
      pageSize: 5,
      botsToDeleteCount: 10,
      loading: false,
      livechatResolverPayload: {
        list: [],
        partitionKey: "",
      },
      cleanVisitorDataPayload: {
        list: [],
        partitionKey: "",
        key: "",
      },
    };
  },
  computed: {
    noOfItems() {
      var sum = 0;
      if (this.activeTab === "botSnapshots") {
        if (this.$store.state.botSnapshots) {
          Object.values(this.$store.state.botSnapshots).forEach((arr) => {
            sum += arr.length;
          });
        }
      }
      return sum;
    },
    botSnapshots() {
      var arr = [];
      if (this.$store.state.botSnapshots) {
        Object.values(this.$store.state.botSnapshots).forEach((snapshotArr) => {
          snapshotArr.forEach((obj) => {
            var temp = _.cloneDeep(obj);
            let filesize = "";
            if (temp.contentLength === 0) {
              filesize = "0 Byte";
            } else {
              const k = 1024;
              const dm = 2;
              const sizes = ["Bytes", "KB", "MB", "GB"];
              const i = Math.floor(Math.log(temp.contentLength) / Math.log(k));
              filesize =
                parseFloat((temp.contentLength / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
            }
            temp.size = filesize;
            temp.name = `${temp.name} (${temp.timeAgo})`;
            temp.createdAt = `${temp.date}, ${temp.time}`;
            arr.push(temp);
          });
        });
      }
      return arr;
    },
    status() {
      return this.$store.state.serverStatus;
    },
    options() {
      return [
        {
          value: "actions",
          label: "Actions",
          children: [
            {
              value: "login",
              label: "Login",
            },
            {
              value: "massLivechatResolve",
              label: "Mass Livechat Resolve",
            },
            {
              value: "stateResetScheduler",
              label: "State Reset Scheduler",
            },
            {
              value: "migrateStateFlowdata",
              label: "Migrate State Flow Data",
            },
            {
              value: "cpuProfile",
              label: "Get CPU profile",
            },
            {
              value: "memorySampling",
              label: "Get memory sampling",
            },
            {
              value: "cleanVisitorData",
              label: "Clean Visitor Data",
            },
          ],
        },
      ];
    },
  },
  mounted() {
    this.$store.dispatch("FETCH_SERVER_STATUS");
  },
  methods: {
    handleChange(value) {
      switch (_.last(value)) {
        case "massLivechatResolve":
          if (!this.livechatResolverPayload.partitionKey) {
            this.$notify.error({
              title: "Error",
              position: "bottom-right",
              message: "Partition Key could not be empty",
            });
            return;
          }
          this.$rest("post", "mass_resolve", this.livechatResolverPayload)
            .then(() => {
              this.$notify({
                title: "Success",
                type: "success",
                position: "bottom-right",
                message: "Successfully mass livechat resolved.",
              });
              this.livechatResolverPayload = {
                list: [],
                partitionKey: "",
              };
            })
            .catch((err) => {
              const message = _.get(
                err,
                "response.data.message",
                "Failed to mass resolve livechat."
              );

              this.$notify.error({
                title: "Error",
                position: "bottom-right",
                message,
              });
            });
          break;
        case "migrateStateFlowdata":
          this.$rest("get", "migrate_state_flow_data")
            .then(() => {
              this.$notify({
                title: "Success",
                type: "success",
                position: "bottom-right",
                message: "Successfully migrated.",
              });
            })
            .catch(() => {
              this.$notify.error({
                title: "Error",
                position: "bottom-right",
                message: "Failed to migrate.",
              });
            });
          break;
        case "stateResetScheduler":
          this.$rest("get", "schedule_reset")
            .then(() => {
              this.$notify({
                title: "Success",
                type: "success",
                position: "bottom-right",
                message: "Successfully resetted.",
              });
            })
            .catch(() => {
              this.$notify.error({
                title: "Error",
                position: "bottom-right",
                message: "Failed to reset.",
              });
            });
          break;
        case "cpuProfile":
          {
            const cpuProfileDuration = _.get(
              this,
              "$store.state.modules.system.resourceInspection.cpuProfileDuration",
              10
            );

            this.$rest("get", `cpu?duration=${cpuProfileDuration}`)
              .then((result) => {
                const url = window.URL.createObjectURL(
                  new Blob([JSON.stringify(result)], {
                    type: "application/json",
                  })
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "profile.cpuprofile");
                document.body.appendChild(link);
                link.click();

                this.$notify({
                  title: "Success",
                  type: "success",
                  position: "bottom-right",
                  message: "Cpu profile retrieved.",
                });
              })
              .catch(() => {
                this.$notify.error({
                  title: "Error",
                  position: "bottom-right",
                  message: "Failed to get cpu profile.",
                });
              });
          }
          break;
        case "memorySampling":
          {
            const memorySamplingDuration = _.get(
              this,
              "$store.state.modules.system.resourceInspection.memorySamplingDuration",
              10
            );

            this.$rest("get", `memory?duration=${memorySamplingDuration}`)
              .then((result) => {
                const url = window.URL.createObjectURL(
                  new Blob([JSON.stringify(result)], {
                    type: "application/json",
                  })
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "memory.heapprofile");
                document.body.appendChild(link);
                link.click();

                this.$notify({
                  title: "Success",
                  type: "success",
                  position: "bottom-right",
                  message: "Memory sampling retrieved.",
                });
              })
              .catch(() => {
                this.$notify.error({
                  title: "Error",
                  position: "bottom-right",
                  message: "Failed to get cpu profile.",
                });
              });
          }
          break;
        case "cleanVisitorData":
          if (_.isEmpty(this.cleanVisitorDataPayload.partitionKey)) {
            this.$notify.error({
              title: "Error",
              position: "bottom-right",
              message: "Partition Key cannot be empty",
            });
            return;
          }

          if (_.isEmpty(this.cleanVisitorDataPayload.key)) {
            this.$notify.error({
              title: "Error",
              position: "bottom-right",
              message: "Key cannot be empty",
            });
            return;
          }

          this.$rest("post", "clean_visitor_data", this.cleanVisitorDataPayload)
            .then((data) => {
              this.$notify({
                title: "Success",
                type: "success",
                position: "bottom-right",
                message: data?.message || "Success",
              });
              this.cleanVisitorDataPayload = {
                list: [],
                partitionKey: "",
                key: "",
              };
            })
            .catch((err) => {
              console.error(err);
              const message = _.get(err, "message", "Failed");

              this.$notify.error({
                title: "Error",
                position: "bottom-right",
                message,
              });
            });
          break;

        default:
      }
    },
    togglePage(pageNo) {
      this.currentPage = pageNo;
      this.refreshTable();
    },
    refreshTable() {
      if (this.activeTab === "botSnapshots") {
        this.tempBotSnapshots = this.botSnapshots.slice(
          (this.currentPage - 1) * this.pageSize,
          this.botSnapshots.length >= this.currentPage * this.pageSize
            ? this.currentPage * this.pageSize
            : this.botSnapshots.length
        );
      }
    },
    handleSelectionChange(val) {
      this.botMultipleSelection = val;
    },
    async handleTabClick(tab, event) {
      if (tab.name === "botSnapshots") {
        await this.$store.dispatch("FETCH_BOT_SNAPSHOTS", {
          brain: this.$store.state.brain,
        });
      } else if (tab.name === "faqSnapshots") {
        await this.$store.dispatch("FETCH_FAQ_SNAPSHOTS", {
          brain: this.$store.state.brain,
        });
      }
      this.refreshTable();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.refreshTable();
    },
    async handleDeleteBotsClick() {
      await this.$confirm(
        "This action will permenently delete bots. Are you sure you want to delete these bots?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      );
      try {
        const data = {
          brain: this.$store.state.brain,
          dateTimes: this.botMultipleSelection.map((bot) => bot.snapshot),
          count: this.botsToDeleteCount,
        };
        this.loading = true;

        await this.$store.dispatch("DESTROY_BOTS", data);
        this.loading = false;
        await this.$message({
          type: "success",
          message: "Deletion complete.",
        });
      } catch (err) {
        await this.$message({
          type: "error",
          message: "Delete failed",
        });
      }
    },
  },
};
</script>

<style scoped>
.el-drawer__wrapper {
  height: 100%;
  width: 40%;
  top: 0;
  left: 0;
}

@media screen and (max-width: 844px) {
  .el-drawer__wrapper {
    width: 100%;
  }
}

.el-tab-pane {
  overflow-y: auto;
  height: calc(100vh - 200px); /* Adjust as needed */
}
</style>
