import { graph } from "@/store/api";
import { RootState } from "@/store/types";
import { ActionTree } from "vuex/types";
import { DepartmentState } from "./types";

import { gql } from "@apollo/client/core";
import _ from "lodash";

const actions: ActionTree<DepartmentState, RootState> = {
  ADD_DEPARTMENT: async ({ state, commit }, { department }) => {
    const response = await graph
      .mutate({
        mutation: gql`
          mutation ($department: JSON) {
            accesscontrolAPI {
              addDepartment(department: $department)
            }
          }
        `,
        variables: {
          department,
        },
      })
      .catch(() => {});
    return _.get(response, "data.accesscontrolAPI.addDepartment");
  },
  FETCH_DEPARTMENTS: async ({ commit }) => {
    const response = await graph
      .query({
        query: gql`
          query departments {
            accesscontrolAPI {
              departments
            }
          }
        `,
        fetchPolicy: "no-cache",
      })
      .catch(() => {});

    const departments = _.get(response, "data.accesscontrolAPI.departments", []);
    commit("SET_DEPARTMENTS", departments);
    return departments;
  },
};

export default actions;
