<template>
  <el-row :gutter="24">
    <!-- FORM -->
    <el-col :span="14">
      <el-form :model="node.content">
        <!-- HEADER -->
        <el-form-item>
          <el-input
            type="text"
            placeholder="Optional"
            maxlength="60"
            v-model="node.content.options.header"
            show-word-limit
          >
            <template slot="prepend">Header</template>
          </el-input>
        </el-form-item>
        <!-- BODY -->
        <el-form-item
          prop="text"
          :rules="[{ required: true, trigger: 'blur', message: 'Please input content body' }]"
        >
          <el-input
            type="textarea"
            placeholder="Add body text"
            maxlength="1024"
            show-word-limit
            v-model="node.content.text"
            rows="5"
          />
        </el-form-item>
        <!-- FOOTER -->
        <el-form-item>
          <el-input
            type="text"
            placeholder="Optional"
            maxlength="60"
            v-model="node.content.options.footer"
            show-word-limit
          >
            <template slot="prepend">Footer</template>
          </el-input>
        </el-form-item>
        <!-- CTA BUTTON TEXT-->
        <el-form-item
          prop="buttons.0.text"
          :rules="[{ required: true, trigger: 'blur', message: 'Please input button text' }]"
        >
          <el-input
            type="text"
            placeholder="Required"
            maxlength="20"
            v-model="node.content.buttons[0].text"
            show-word-limit
          >
            <template slot="prepend">Button Text</template>
          </el-input>
        </el-form-item>
        <!-- CTA BUTTON URL -->
        <el-form-item
          prop="buttons.0.url"
          :rules="[
            { required: true, trigger: 'blur', message: 'Please input button URL' },
            { max: 2000, message: 'URL cannot exceed 2000 characters', trigger: 'blur' },
          ]"
        >
          <template slot="label">Button URL</template>
          <el-input
            type="textarea"
            placeholder="Required - https://example.com"
            v-model="node.content.buttons[0].url"
            maxlength="2000"
            show-word-limit
            rows="3"
          />
        </el-form-item>
      </el-form>
    </el-col>

    <!-- PREVIEW -->
    <el-col :span="10">
      <div class="preview-label">Example:</div>
      <div class="preview-container">
        <el-image :src="require('@/assets/wa-cta.png')" fit="contain" class="preview-image" />
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "WhatsAppCTA",
  props: ["node", "editable"],
  created() {
    // Initialize required fields if they don't exist
    if (!this.node.content.options) {
      this.$set(this.node.content, "options", {});
    }
    if (!this.node.content.buttons) {
      this.$set(this.node.content, "buttons", [
        {
          text: "",
          url: "",
        },
      ]);
    }
  },
  data() {
    return {};
  },
  methods: {
    validationStyle(field) {
      return this["valid_" + field] ? null : { color: "red" };
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/colors.scss";

.interactive-message-section {
  border: 1px solid $color-light;
  padding: 0px 25px;
  padding-top: 25px;
  padding-bottom: 15px;
  margin: 10px 0;
  border-radius: 5px;
  position: relative;
}

.preview-container {
  padding: 20px;
  background: #e5ddd5;
  border-radius: 8px;
  min-height: 400px;
}

.preview-image {
  width: 100%;
  height: auto;
}

.preview-label {
  font-size: 14px;
  color: #606266;
  margin-bottom: 8px;
}
</style>
