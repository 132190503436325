<template>
  <div>
    <template v-if="node">
      <!-- Versioning -->
      <el-alert
        style="margin-bottom: 10px"
        :title="`There are ${siblings.length + 1} versions of this content`"
        type="info"
        :closable="false"
      >
        <span style="display: flex; align-items: flex-start; flex-flow: row wrap">
          <el-button
            round
            :type="id === value ? 'primary' : 'default'"
            size="mini"
            style="margin: 0 5px 5px 0; padding: 5px 10px"
            @click="selectNode(baseId)"
            >{{ baseId }}</el-button
          >
          <el-button
            round
            size="mini"
            style="margin: 0 5px 5px 0; padding: 5px 10px"
            v-for="id in siblings"
            :key="id"
            :type="id === value ? 'primary' : 'default'"
            @click="selectNode(id)"
            >{{ getVersionId(id) }}</el-button
          >

          <!-- Creating new version -->
          <el-popover
            v-model="showNewVersionPopover"
            :disabled="!isAuthorisedForChange"
            placement="bottom"
          >
            <el-input
              v-model.trim="newVersionId"
              autofocus
              @keyup.enter.native="addVersion"
              @keyup.esc.native="showNewVersionPopover = false"
            >
              <span slot="prepend">Version ID</span>
              <el-button slot="append" icon="el-icon-check" @click="addVersion" />
            </el-input>
            <el-button
              round
              size="mini"
              type="primary"
              plain
              slot="reference"
              class="item"
              style="margin: 0 5px 5px 0; padding: 5px 10px"
            >
              <i class="el-icon-plus"></i>
              Add version
            </el-button>
          </el-popover>
        </span>
      </el-alert>

      <!-- Main card -->
      <el-card :key="componentKey">
        <!-- Header -->
        <div slot="header">
          <el-row type="flex" justify="space-between">
            <div>
              <ContentNodeEditorBackButton :node="node" />
            </div>

            <div>
              <el-select
                v-model="node.department"
                :disabled="!isAuthorisedForChange"
                multiple
                filterable
                placeholder="Department"
                size="small"
                style="margin-right: 1em"
              >
                <el-option
                  v-for="department in formattedDepartments"
                  :key="department"
                  :label="department"
                  :value="department.toLowerCase()"
                >
                  <span style="float: left">{{ department }}</span>
                  <span style="float: right; width: 20px" />
                </el-option>
              </el-select>

              <el-tooltip effect="dark" content="Save node" placement="bottom">
                <el-button
                  :disabled="!valid || !isAuthorisedForChange"
                  :loading="saving"
                  icon="el-icon-check"
                  :plain="true"
                  size="small"
                  type="success"
                  circle
                  @click="saveChanges"
              /></el-tooltip>
              <el-dropdown @command="add">
                <el-tooltip effect="dark" content="Add new.." placement="top">
                  <el-button
                    class="el-dropdown-link"
                    icon="el-icon-plus"
                    type="primary"
                    plain
                    size="small"
                    circle
                  />
                </el-tooltip>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :disabled="!isAuthorisedForChange" command="textVariation">
                    Text Variation
                  </el-dropdown-item>
                  <template v-if="modules.openai.enabled && modules.openai.textImprovement">
                    <el-dropdown-item :disabled="!isAuthorisedForChange" command="improveText">
                      Improve Answer
                    </el-dropdown-item>
                  </template>
                  <!-- <el-dropdown-item command="button">Button</el-dropdown-item> -->
                  <!-- <el-dropdown-item command="quickReply">Quick reply</el-dropdown-item> -->
                  <el-dropdown-item :disabled="!isAuthorisedForChange" command="card">
                    Card
                  </el-dropdown-item>
                  <el-dropdown-item :disabled="!isAuthorisedForChange" command="trigger">
                    Trigger
                  </el-dropdown-item>
                  <el-dropdown-item command="duplicate">Duplicate content</el-dropdown-item>
                  <el-dropdown-item :disabled="!isAuthorisedForChange" command="keyword">
                    Keyword
                  </el-dropdown-item>
                  <el-dropdown-item :disabled="!isAuthorisedForChange" command="gifimage">
                    GIF/Image
                  </el-dropdown-item>
                  <el-dropdown-item :disabled="!isAuthorisedForChange" command="list">
                    List
                  </el-dropdown-item>
                  <el-dropdown-item :disabled="!isAuthorisedForChange" command="video">
                    Video
                  </el-dropdown-item>
                  <template v-if="isUiBetaEnabled">
                    <el-dropdown-item divided :disabled="!isAuthorisedForChange" command="grid">
                      Grid
                      <el-tooltip
                        style="margin-left: 5px"
                        placement="top"
                        trigger="hover"
                        content="Web Chat only"
                      >
                        <span>🌐</span>
                      </el-tooltip>
                    </el-dropdown-item>
                    <el-dropdown-item :disabled="!isAuthorisedForChange" command="slider">
                      Slider
                      <el-tooltip
                        style="margin-left: 5px"
                        placement="top"
                        trigger="hover"
                        content="Web Chat only"
                      >
                        <span>🌐</span>
                      </el-tooltip>
                    </el-dropdown-item>
                  </template>
                  <template v-if="modules.whatsapp.enabled">
                    <el-dropdown-item
                      divided
                      :disabled="!isAuthorisedForChange"
                      command="whatsapp_list"
                    >
                      <span style="vertical-align: middle">Interactive List</span>
                      <el-tooltip
                        style="margin-left: 5px; vertical-align: middle"
                        placement="top"
                        trigger="hover"
                        content="WhatsApp only"
                      >
                        <img src="@/assets/whatsapp.png" alt="" />
                      </el-tooltip>
                    </el-dropdown-item>
                    <el-dropdown-item
                      :disabled="!isAuthorisedForChange"
                      command="whatsapp_product_message"
                    >
                      <span style="vertical-align: middle">Product Message</span>
                      <el-tooltip
                        style="margin-left: 5px; vertical-align: middle"
                        placement="top"
                        trigger="hover"
                        content="WhatsApp only"
                      >
                        <img src="@/assets/whatsapp.png" alt="" />
                      </el-tooltip>
                    </el-dropdown-item>
                    <el-dropdown-item
                      :disabled="!isAuthorisedForChange"
                      command="whatsapp_location_message"
                    >
                      <span style="vertical-align: middle">Location Message</span>
                      <el-tooltip
                        style="margin-left: 5px; vertical-align: middle"
                        placement="top"
                        trigger="hover"
                        content="WhatsApp only"
                      >
                        <img src="@/assets/whatsapp.png" alt="" />
                      </el-tooltip>
                    </el-dropdown-item>
                    <el-dropdown-item
                      :disabled="!isAuthorisedForChange"
                      command="whatsapp_contact_message"
                    >
                      <span style="vertical-align: middle">Contact Message</span>
                      <el-tooltip
                        style="margin-left: 5px; vertical-align: middle"
                        placement="top"
                        trigger="hover"
                        content="WhatsApp only"
                      >
                        <img src="@/assets/whatsapp.png" alt="" />
                      </el-tooltip>
                    </el-dropdown-item>
                    <!-- WHATSAPP CTA -->
                    <el-dropdown-item :disabled="!isAuthorisedForChange" command="whatsapp_cta">
                      <span style="vertical-align: middle">Call-To-Action</span>
                      <el-tooltip
                        style="margin-left: 5px; vertical-align: middle"
                        placement="top"
                        trigger="hover"
                        content="WhatsApp only"
                      >
                        <img src="@/assets/whatsapp.png" alt="" />
                      </el-tooltip>
                    </el-dropdown-item>
                    <!-- END WHATSAPP CTA -->
                  </template>
                </el-dropdown-menu>
              </el-dropdown>
              <el-dropdown
                v-if="hasContentHistory"
                :disabled="!isAuthorisedForChange"
                :hide-on-click="false"
              >
                <el-tooltip effect="dark" content="View history" placement="bottom">
                  <el-button :plain="true" icon="el-icon-time" circle />
                </el-tooltip>
                <el-dropdown-menu
                  slot="dropdown"
                  :disabled="!isAuthorisedForChange"
                  style="max-height: 500px; overflow-y: auto"
                >
                  <el-dropdown-item
                    v-for="(obj, index) in changes[node.id]"
                    :key="index"
                    @click.native="selectVersion(obj.snapshotId)"
                    >{{ obj.display }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <el-button
                v-else
                :plain="true"
                icon="el-icon-time"
                type="info"
                circle
                size="small"
                @click="$store.dispatch('FETCH_CONTENT_SNAPSHOTS')"
              />
              <template v-if="isSystemNode">
                <el-tooltip
                  effect="dark"
                  content="This is a system generated node, rename and delete actions are not available."
                  placement="bottom"
                >
                  <el-button
                    style="margin-left: 0px"
                    icon="el-icon-lock"
                    type="warning"
                    plain
                    circle
                    size="small"
                  />
                </el-tooltip>
              </template>
              <template v-else>
                <el-tooltip effect="dark" content="Delete node" placement="bottom">
                  <el-button
                    type="danger"
                    :disabled="currentNotSelected || !isAuthorisedForChange || isSystemNode"
                    style="margin-left: 0px"
                    :loading="deleting"
                    icon="el-icon-delete"
                    circle
                    size="small"
                    @click="deleteNode"
                  />
                </el-tooltip>
              </template>
              <el-tooltip
                v-if="showAdvanced"
                placement="top"
                trigger="hover"
                content="Calculate Button Stats"
              >
                >
                <el-button
                  :plain="true"
                  icon="el-icon-data-analysis"
                  type="info"
                  circle
                  size="small"
                  @click="fetchStats"
                />
              </el-tooltip>
            </div>
          </el-row>

          <div>
            <el-input
              v-if="editBaseId"
              v-model.trim="newBaseId"
              style="width: 100%"
              ref="nodeIdEditor"
              @keyup.esc.native="editBaseId = false"
              @keyup.enter.native="renameNode"
              :disabled="node.is_default"
              @blur="renameNode"
            >
              <el-button
                slot="append"
                icon="el-icon-check"
                :loading="saving"
                @click="renameNode"
                :disabled="!isAuthorisedForChange"
              />
            </el-input>

            <el-tooltip
              v-else
              effect="dark"
              :content="
                node.is_default
                  ? 'System generated node are not able to be renamed.'
                  : 'Change node ID.'
              "
              placement="top"
            >
              <h2 class="nodeId" @click="toggleRenameNodeId()">
                {{ baseId }}
              </h2> </el-tooltip
            >&nbsp;
            <span v-if="versionId">
              <el-input
                v-if="editVersionId"
                v-model.trim="newVersionId"
                style="width: 200px"
                @keyup.esc.native="editBaseId = false"
                @keyup.enter.native="renameNode"
              >
                <el-button
                  slot="append"
                  icon="el-icon-check"
                  @click="renameNode"
                  :disabled="!isAuthorisedForChange"
                />
              </el-input>

              <el-tooltip v-else effect="dark" content="Change version ID" placement="top">
                <el-tag class="versionTag" type="primary" @click.native="toggleRenameVersionId()">{{
                  versionId
                }}</el-tag> </el-tooltip
              >&nbsp;
            </span>
          </div>

          <br />

          <!-- CONDITIONS -->
          <div style="padding: 5px" v-if="node.conditions && node.conditions.length > 0">
            <el-divider style="font-size: 0.7em">Conditions</el-divider>
            <div v-if="identicalSiblings.length" class="duplicate">
              <el-tooltip
                style="margin-right: 5px"
                :content="`This trigger has the same text pattern and same context`"
                placement="top"
              >
                <i class="el-icon-warning" /> </el-tooltip
              >Duplicated condition :
              <strong
                v-for="(identicalSibling, index) in identicalSiblings"
                :key="`siblings-${index}`"
                @click="$emit('select', { type: 'content', id: identicalSibling })"
                >{{ getIdenticalSiblingsDisplay(identicalSibling, index) }}
              </strong>
            </div>
            <el-row
              v-for="(condition, index) in node.conditions"
              :key="index"
              type="flex"
              style="align-items: center; flex-direction: row"
            >
              <el-select
                v-model="condition.property"
                filterable
                allow-create
                default-first-option
                size="small"
                placeholder="Choose a property"
                style="width: 35%; margin-right: 5px"
              >
                <!-- this options list from contentNode.ts // contentNodeOptionsMixin -->
                <el-option-group
                  v-for="(conditionPropertyOption, index) in conditionPropertyOptionsDynamic"
                  :key="`condition-property-group-option-${index}`"
                  :label="conditionPropertyOption.name"
                >
                  <el-option
                    v-for="(property, propertyIndex) in conditionPropertyOption.options"
                    :key="`condition-property-option-${propertyIndex}`"
                    :label="property.name"
                    :value="property.value"
                  ></el-option>
                </el-option-group>
              </el-select>
              <el-select
                placeholder="Operator"
                filterable
                :value="condition.operator"
                @change="changeOperator(condition, $event)"
                size="small"
                style="width: 15%; margin-right: 5px"
              >
                <el-option
                  :key="`condition-operator-option-${index}`"
                  v-for="(conditionOperatorOption, index) in filterValidOperator(
                    condition.valueType
                  )"
                  :value="conditionOperatorOption.value"
                  :label="conditionOperatorOption.name"
                />
              </el-select>

              <el-select
                :key="
                  ['INCLUDED IN', 'NOT INCLUDED IN'].includes(condition.operator)
                    ? 'select-multiple'
                    : 'select-single'
                "
                v-if="showEntitySelector(condition)"
                size="small"
                filterable
                :multiple="['INCLUDED IN', 'NOT INCLUDED IN'].includes(condition.operator)"
                v-model="condition.value"
                placeholder="Select Entity"
              >
                <el-option
                  v-for="item in entityValues(condition)"
                  :key="item.value"
                  :label="item.value"
                  :value="item.value"
                ></el-option>
              </el-select>
              <div
                style="display: flex; align-items: center; width: 45%"
                v-else-if="!['EXISTS', 'NOT EXISTS'].includes(condition.operator)"
              >
                <el-select
                  class="value-type"
                  placeholder="Type"
                  :value="condition.valueType || 'JSON'"
                  @change="changeValueType(condition, $event)"
                  filterable
                  size="small"
                  style="width: 30%; margin-right: -1px"
                >
                  <el-option
                    :key="`condition-operator-option-${index}`"
                    v-for="(conditionOperatorOption, index) in conditionValueTypeOptions"
                    :value="conditionOperatorOption.value"
                    :label="conditionOperatorOption.name"
                  />
                </el-select>
                <el-input-number
                  class="value-field"
                  v-if="condition.valueType === 'NUMBER'"
                  style="width: 65%; margin-right: 5px"
                  v-model="condition.value"
                  size="small"
                  controls-position="right"
                />
                <el-select
                  class="value-field"
                  v-else-if="condition.valueType === 'BOOLEAN'"
                  placeholder="Value Type"
                  v-model="condition.value"
                  filterable
                  size="small"
                  style="width: 65%; margin-right: 5px"
                >
                  <el-option :value="false" label="FALSE" />
                  <el-option :value="true" label="TRUE" />
                </el-select>
                <el-autocomplete
                  class="value-field"
                  v-else-if="condition.valueType === 'JSON'"
                  style="width: 65%; margin-right: 5px"
                  v-model="condition.value"
                  type="textarea"
                  rows="1"
                  size="small"
                  :fetch-suggestions="querySearch(condition)"
                />
                <el-autocomplete
                  v-else
                  class="value-field"
                  style="width: 65%; margin-right: 5px"
                  v-model="condition.value"
                  size="small"
                  :fetch-suggestions="querySearch(condition)"
                />
              </div>
              <el-button
                type="text"
                icon="el-icon-delete"
                size="mini"
                circle
                @click="removeCondition(index)"
              />
            </el-row>
          </div>

          <el-row>
            <el-button round @click.prevent="addCondition" size="mini" type="primary" plain>
              <i class="el-icon-plus"></i>
              Add Condition
            </el-button>
            <el-tooltip
              class="item"
              effect="dark"
              content="Valid operators depends on value type selected"
              placement="right"
            >
              <i class="el-icon-info" style="color: grey; margin-left: 5px"></i>
            </el-tooltip>
          </el-row>

          <!-- KEYWORDS -->
          <div
            v-if="(node.keywords && node.keywords.length > 0) || editKeyword"
            style="padding: 5px"
          >
            <span style="font-weight: bold; font-size: 0.7em">Keywords</span>
            <el-row>
              <el-col>
                <el-tag
                  v-for="(keyword, index) in node.keywords"
                  :key="index"
                  type="primary"
                  class="keywordTag"
                  :closable="true"
                  @click="editKeyword = true"
                  @close="deleteKeyword(index)"
                  >{{ keyword.value }}</el-tag
                >
                <el-input
                  v-if="editKeyword"
                  v-model="newKeyword.value"
                  size="mini"
                  style="width: 200px"
                  @keyup.esc.native="editKeyword = false"
                  @keyup.enter.native="newKeywordConfirm(newKeyword)"
                  @blur="newKeywordConfirm(newKeyword)"
                />
                <el-button
                  v-else
                  class="button-new-keyword"
                  size="small"
                  @click="editKeyword = true"
                  >+ New Keyword</el-button
                >
              </el-col>
            </el-row>
          </div>
        </div>

        <el-alert
          v-if="nodeHistoryType !== 'Current'"
          :closable="false"
          show-icon
          center
          type="warning"
        >
          <span slot="title"
            >This is a preview of the content as it was at {{ nodeHistoryType | fromNow }} ({{
              nodeHistoryType
            }})</span
          >
          <span> Click on the save button to rollback to this version. </span>
        </el-alert>
        <!-- Content -->
        <ContentNode :node="node" />

        <br />

        <div
          v-if="node.next"
          style="width: 100%; text-align: center; margin-top: 10px; margin-bottom: 10px"
        >
          <div>
            <el-checkbox v-model="isStickyMenu">
              Make it sticky menu for
              <el-input
                type="text"
                class="sticky-input"
                v-model="stickyMenuLangCode"
                :disabled="!isStickyMenu"
              />
              language
            </el-checkbox>
            <el-tooltip
              style="margin-left: 10px; vertical-align: middle"
              effect="dark"
              placement="top"
            >
              <i class="el-icon-question" style="color: grey"></i>
              <div slot="content" style="max-width: 400px; white-space: normal">
                {{ stickyCheckBoxTooltip }}
              </div>
            </el-tooltip>
          </div>
        </div>

        <!-- Quick Reply Display Mode Options for Webchat (will overwrite global config in webchat module) -->
        <div style="width: 100%; text-align: center; margin-top: 10px; margin-bottom: 10px">
          <QuickReplyDisplayMode @updateNode="updateNode" :node="node" />
        </div>

        <JSONEditor
          v-if="showAdvanced"
          ref="jsonEditor"
          v-model="node"
          @valid="valid = true"
          @invalid="valid = false"
        />
      </el-card>

      <!-- Select User Feedback Mode for Webchat -->
      <div
        v-if="feedbackCollectionOnContentNode"
        style="width: 100%; text-align: center; margin-top: 10px"
      >
        <UserFeedbackMode @updateNode="updateNode" :node="node" />
      </div>

      <!-- Others -->
      <div v-if="node?.next !== undefined && depth() < 5">
        <div class="edge-settings">
          <div>
            <el-slider
              v-if="node.delay"
              class="delay-slider"
              v-model="node.delay"
              label="delay"
              :min="1"
              :max="60"
              :format-tooltip="(i) => `${i} seconds`"
            />

            <el-button
              class="button-remove-delay"
              v-if="node.delay"
              type="text"
              icon="el-icon-close"
              @click="removeDelay"
              >Delay {{ node.delay }} seconds</el-button
            >
            <el-button v-else type="text" icon="el-icon-plus" @click="addDelay">Delay</el-button>
          </div>

          <el-steps
            direction="vertical"
            :active="2"
            style="width: 24px; height: 100px; margin: auto"
          >
            <el-step />
            <el-step />
          </el-steps>
          <el-button class="button-remove-link" type="text" icon="el-icon-close" @click="removeNext"
            >Link</el-button
          >
        </div>

        <div v-if="node.next">
          <el-card style="margin: 20px auto; width: 500px">
            <EventNodeSelector v-model="node.next" />
            <!-- @save-side-effect="$emit('save-side-effect')"  -->
          </el-card>

          <ContentNodeEditor v-if="typeof node.next === 'string'" v-model="node.next" />
          <ContentNodeEditor v-else-if="node.next.event === 'goto'" v-model="node.next.data" />
        </div>
      </div>

      <div v-else class="node-settings-block">
        <!-- Toggle User Input for Webchat -->
        <div class="node-settings">
          <el-checkbox @change="toggleUserInput" :value="!node.options.disableTextInput"
            >Allow user input</el-checkbox
          >
          <br />

          <div>
            <el-checkbox v-model="isStickyMenu">
              Make it sticky menu for
              <el-input
                type="text"
                class="sticky-input"
                v-model="stickyMenuLangCode"
                :disabled="!isStickyMenu"
              />
              language
            </el-checkbox>
            <el-tooltip
              style="margin-left: 10px; vertical-align: middle"
              effect="dark"
              placement="top"
            >
              <div slot="content" style="max-width: 400px; white-space: normal">
                {{ stickyCheckBoxTooltip }}
              </div>
              <i class="el-icon-question" style="color: grey"></i>
            </el-tooltip>
          </div>
        </div>

        <br />

        <el-button type="text" icon="el-icon-plus" @click="addNext">Next step</el-button>
      </div>
    </template>
    <template v-else>
      <el-card style="max-width: 50%; margin: 20px auto">
        <div v-if="id">
          <p>
            Content
            <el-tag>{{ id }}</el-tag
            >does not exist.
          </p>
          <p>Do you want to create it?</p>
          <el-button type="primary" :plain="true" @click="id = ''">Cancel</el-button>

          <el-button type="primary" @click="createContentNode()">Create</el-button>
        </div>
        <div v-else>
          <el-autocomplete
            v-model="tempNode.id"
            style="width: 70%"
            placeholder="New name"
            :fetch-suggestions="getContentNodes"
          >
            <div slot="prepend">Name</div>
          </el-autocomplete>
          <el-button v-if="nodeExist" type="primary" @click="id = tempNode.id">Load</el-button>
          <el-button v-else type="primary" @click="createContentNode()">Create</el-button>
        </div>
      </el-card>
    </template>

    <el-dialog title="Answer Improvement" :visible.sync="textImprovement">
      <div v-loading="improvingText">
        <div class="answer-bubble-text" sty>
          {{ currentText }}
        </div>
        <hr style="margin-bottom: 15px" />
        <el-row :gutter="20">
          <el-col :span="12" v-for="item in textChoices" :key="'text' + item.index">
            <h3>Alternative Answer #{{ item.index + 1 }}</h3>
            <div class="answer-bubble-text">
              {{ item.text }}
            </div>
            <div style="text-align: center">
              <el-button type="success" size="small" @click="chooseText(item.index)">
                Accept Improvement
              </el-button>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import _, { isArray } from "lodash";
import Component from "vue-class-component";
import JSONEditor from "@/components/JSONEditor";
import EventNodeSelector from "@/components/EventNodeSelector/Index";
import {
  contentNodeConditionMethodMixin,
  contentNodeConditionOptionsDataMixin,
  contentNodeConditionOptionsComputedMixin,
} from "@/mixins/contentNode";
import ContentNode from "@/components/ContentNode";
import QuickReplyDisplayMode from "@/components/QuickReplyDisplayMode";
import UserFeedbackMode from "@/components/UserFeedbackMode";
import { getDefaultPlaceholderImage } from "@/helperMethods/util";
import ContentNodeEditorBackButton from "@/components/ContentNodeEditorBackButton.vue";
import errorHandlerMixin from "@/mixins/errorHandler";

//if we use class component then no need to import same component
//import ContentNodeEditor from "@/components/ContentNodeEditor";
import * as moment from "moment";
import { isValidContentName } from "@/helperMethods/task_management/content";
import { mapGetters } from "vuex";
import * as jsondiffpatch from "jsondiffpatch/dist/jsondiffpatch.umd";
import { mapChanges } from "@/helperMethods/modules";

@Component({
  name: "ContentNodeEditor",
  mixins: [
    contentNodeConditionOptionsDataMixin,
    contentNodeConditionMethodMixin,
    contentNodeConditionOptionsComputedMixin,
    errorHandlerMixin,
  ],
  components: {
    QuickReplies: () => import("@/components/QuickReplies"),
    JSONEditor,
    EventNodeSelector,
    ContentNodeEditor: () => import("@/components/ContentNodeEditor"),
    ContentNode,
    QuickReplyDisplayMode,
    UserFeedbackMode,
    ContentNodeEditorBackButton,
  },
  props: ["value"],
  data() {
    return {
      originalModules: {},
    };
  },
  methods: {
    mapChanges,
    saveOriginalModules() {
      this.originalModules = _.cloneDeep(this.modules);
    },
    changeOperator(condition, value) {
      condition.operator = value;
      if (value.includes("EXISTS")) {
        condition.valueType = "JSON";
        condition.value = "";
      }
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    filterValidOperator(valueType) {
      return this.conditionOperatorOptions.filter(
        (item) => !valueType || item.valid.includes(valueType)
      );
    },
    changeValueType(condition, value) {
      condition.valueType = value;
      const operatorStillValid = this.conditionOperatorOptions.find(
        (item) => item.value === condition.operator && item.valid.includes(value)
      );
      if (!operatorStillValid) {
        condition.operator = "";
      }
      if (value === "STRING" || value === "JSON") {
        condition.value = "";
      }
      if (value === "NUMBER") {
        condition.value = 0;
      }
      if (value === "BOOLEAN") {
        condition.value = false;
      }
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    updateNode(newNode) {
      this.node = newNode;
      this.$forceUpdate();
    },
    toggleUserInput(value) {
      _.set(this.node, "options.disableTextInput", !value);
      // added this line to trigger value change
      this.node = this.node;
      this.$forceUpdate();
    },
    getIdenticalSiblingsDisplay(siblingId, i) {
      return `${siblingId}${i !== this.identicalSiblings.length - 1 ? ", " : ""}`;
    },
    toggleRenameNodeId() {
      if (!this.node.is_default && this.isAuthorisedForChange) {
        this.editBaseId = true;
      }
    },
    toggleRenameVersionId() {
      if (this.isAuthorisedForChange) {
        this.editVersionId = true;
      }
    },
    saveStickyMenu(id) {
      let stickyMenu = _.cloneDeep(this.stickyMenu);

      if (this.isStickyMenu) {
        if (this.isLanguageCodeExisted) {
          this.saving = false;
          this.$message({
            type: "error",
            message: "Error: Same language code exists",
          });
          return false;
        }
        if (_.isEmpty(this.stickyMenuLangCode)) {
          this.saving = false;
          this.$message({
            type: "error",
            message: "Please input language code",
          });
          return false;
        }
        this.$set(stickyMenu, id, this.stickyMenuLangCode);
      } else {
        this.$delete(stickyMenu, id);
      }

      const isStickyMenuConfigChanged = !_.isEqual(stickyMenu, this.oldStickyMenuSetting);

      if (isStickyMenuConfigChanged) {
        this.$set(this.modules.webchat, "stickyMenu", stickyMenu);
        const changes = jsondiffpatch.diff(this.originalModules, this.modules);
        this.mapChanges(changes);
        this.$store.dispatch("SAVE_MODULES", {
          modules: changes,
        }).then(() => {
          this.saveOriginalModules();
        });
        this.oldStickyMenuSetting = _.cloneDeep(stickyMenu);
        this.isStickyMenuConfigChanged = isStickyMenuConfigChanged;
      }
      return true;
    },
    initStickyValue(value) {
      this.stickyMenuLangCode = this.stickyMenu[value];
      this.isStickyMenu = _.has(this.stickyMenu, value);
    },
  },
  computed: {
    ...mapGetters(["isUiBetaEnabled", "changes", "showAdvanced"]),
    departments() {
      return this.$store.getters.userDepartment;
    },
    isSystemNode() {
      return this.node.is_default && !this.newVersionId;
    },
    formattedDepartments() {
      const departments = this.departments;
      let formattedDepartments = [];
      if (departments) {
        formattedDepartments = _.map(departments, (dept) => {
          return dept
            .split(" ")
            .map((word) => _.capitalize(word))
            .join(" ")
            .trim();
        });

        formattedDepartments = _.uniq(formattedDepartments);
      }
      return formattedDepartments;
    },

    nodeHistoryType: {
      get() {
        if (!this.$store.state.activeNodeHistoryType) {
          this.$set(this.$store.state, "activeNodeHistoryType", "Current");
        }
        return this.$store.state.activeNodeHistoryType;
      },
      set(value) {
        this.$store.state.activeNodeHistoryType = value;
      },
    },
    id: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.value = value;
        this.$emit("input", value);
      },
    },
    node: {
      get() {
        this.newNodeId = this.id;
        this.editNodeId = false;
        // if (this.origId == "" || this.origId != this.id) {
        //   this.origId = this.id;
        //   this.origNode = this.$store.state.nodes.content[this.id];
        // }
        this.newBaseId = this.baseId;
        this.newVersionId = this.versionId;

        this.editBaseId = false;
        this.editVersionId = false;

        const existingNode = this.$store.state.nodes.content[this.id];
        if (existingNode) {
          if (this.old_node_id !== this.id && !this.renameContentId) {
            this.old_node_id = this.id;
            this.old_node = _.cloneDeep(existingNode);
          }
          if (!existingNode.department || existingNode.department.length === 0)
            existingNode.department = ["general"];

          if (
            existingNode.department &&
            existingNode.department.length > 1 &&
            existingNode.department[0] === "general"
          ) {
            existingNode.department.splice(0, 1);
          }

          if (!existingNode.options) {
            existingNode.options = {
              disableTextInput: false,
              feedbackCollectionMode: "default",
            };
          }

          if (typeof existingNode.next === "string") {
            existingNode.next = {
              event: "goto",
              data: existingNode.next,
            };
          }

          if (existingNode.type === "card" && Array.isArray(existingNode.content)) {
            // Add default options to cards
            existingNode.content = _.map(existingNode.content, (item) => {
              const itemIsString = typeof item === "string";
              if (!itemIsString && !item.options) {
                return {
                  ...item,
                  options: {
                    padded: false,
                  },
                };
              }
              return item;
            });
          }

          if (!existingNode.id) {
            existingNode.id = this.id;
          }

          return existingNode;
        } else {
          this.tempNode.id = this.id;
          return null;
        }
      },
      set(value) {
        this.$store.state.nodes.content[this.id] = value;
      },
    },
    isAuthorisedForChange: {
      get() {
        const result =
          this.departments &&
          (_.intersection(this.departments, this.node.department).length > 0 ||
            this.node.department.includes("general"));
        return result;
      },
    },
    identicalSiblings() {
      const existingNode = this.$store.state.nodes.content[this.id];
      const existingNodeConditions = _.get(existingNode, "conditions");
      const allVersions = [this.baseId, ...this.siblings];
      const identicalSiblings = _.chain(allVersions)
        .filter((v) => v !== this.id)
        .map((nodeId) => ({
          conditions: _.get(this.$store.state.nodes.content, `[${nodeId}].conditions`, []),
          nodeId,
        }))
        .filter((nodeConditions) =>
          _.chain(nodeConditions)
            .cloneDeep()
            .omit("nodeId")
            .get("conditions", [])
            .isEqual(existingNodeConditions)
            .value()
        )
        .map("nodeId")
        .value();
      return identicalSiblings;
    },
    modules: {
      get() {
        return this.$store.state.modules;
      },
      set(value) {
        this.$store.state.modules = value;
      },
    },
    stickyMenu() {
      return this.modules.webchat.stickyMenu ?? {};
    },
    listLanguageCode() {
      const languageCode = _.map(this.stickyMenu, (lang, node) => lang);
      return languageCode;
    },
    isLanguageCodeExisted() {
      return (
        _.includes(this.listLanguageCode, this.stickyMenuLangCode) &&
        this.stickyMenuLangCode !== this.stickyMenu[this.id]
      );
    },
  },
  mounted() {
    this.saveOriginalModules();
  },
  created() {
    this.initStickyValue(this.id);
    this.oldStickyMenuSetting = _.cloneDeep(this.modules.webchat.stickyMenu);

    // PR 7730
    this.unwatch = this.$store.watch(
      (state) => state.nodes,
      (newValue) => {
        const existingNode = _.cloneDeep(newValue.content[this.id]);
        if (existingNode) {
          this.old_node = existingNode;
        }
      }
    );
  },
  beforeDestroy() {
    this.unwatch();
  },
  watch: {
    id: {
      handler(value) {
        this.initStickyValue(value);
      },
    },
  },
})
export default class ContentNodeEditor extends Vue {
  componentKey = 0;
  nodeType = "content";
  selectedCardIndex = 0;
  stats = [];
  editBaseId = false;
  editVersionId = false;
  editKeyword = false;
  newBaseId = "";
  newVersionId = "";
  old_node_id = "";
  old_node = {};
  renameContentId = false;
  saving = false;
  deleting = false;
  valid = true;
  tempNode = {
    id: "",
    content: {},
  };
  origNode = "";
  origId = "";
  newKeyword = {};
  showNewVersionPopover = false;
  isStickyMenu = false;
  oldStickyMenuSetting = {};
  stickyMenuLangCode = "";
  isStickyMenuConfigChanged = false;
  stickyCheckBoxTooltip =
    "Add the language code for which you want to show this content. For example, if you want to show this sticky menu in the English language, add the code 'en' consistently across the whole platform.";
  currentText = "";
  textChoices = [];
  textImprovement = false;
  improvingText = false;
  get hasContentHistory() {
    return _.get(this.changes, this.id, []).length > 1;
  }

  get currentNotSelected() {
    return this.nodeHistoryType !== "Current" || !this.nodeHistoryType;
  }

  get idParts() {
    if (this.value && typeof this.value === "string") {
      return this.value.split(":");
    }
    return this.value;
  }
  get baseId() {
    return _.get(this, "idParts[0]", "");
  }
  get versionId() {
    return _.get(this, "idParts[1]", "");
  }
  get siblings() {
    return _.keys(this.$store.state.nodes.content).filter(
      (nodeId) => nodeId.indexOf(this.baseId + ":") === 0
    );
  }
  get nodeExist() {
    return this.$store.state.nodes.content[this.tempNode.id];
  }
  get feedbackCollectionOnContentNode() {
    return _.get(this, "$store.state.modules.faq.feedbackCollection.enabledForContentNode", false);
  }

  getVersionId(id) {
    let parts = id.split(":");
    if (parts.length > 1) {
      return parts[1];
    } else {
      return id;
    }
  }

  removeCondition(index) {
    this.node.conditions.splice(index, 1);
  }
  selectNode(id) {
    this.$store.dispatch("SELECT_NODE", { type: "content", id });
  }
  back() {
    this.$store.dispatch("SELECT_PREVIOUS_NODE");
  }
  depth() {
    let count = 1;
    let parent = this.$parent.$parent;
    while (parent.id) {
      count++;
      parent = parent.$parent.$parent;
    }

    return count;
  }
  getContentNodes(value, cb) {
    cb(this.$store.state.search.content.search(value || ""));
  }

  async fetchStats() {
    this.stats = await this.$store.dispatch("FETCH_BUTTON_STATS", this.id);

    let buttons = [];
    let quickReplies = this.node.quickReplies;

    if (!Array.isArray(this.node.content)) {
      buttons = this.node.content.buttons;
    } else {
      this.node.content.forEach((card) => {
        buttons = buttons.concat(card.buttons);
      });
    }

    buttons.forEach((button) => {
      const found = _.find(this.stats, {
        event: button.event.toUpperCase(),
        data: button.data,
      });

      if (found) {
        button.count = found.count;
      } else {
        button.count = 0;
      }
    });

    quickReplies.forEach((qr) => {
      const found = _.find(this.stats, {
        event: qr.event.toUpperCase(),
        data: qr.data,
      });

      if (found) {
        qr.count = found.count;
      } else {
        qr.count = 0;
      }
    });

    this.componentKey++;
  }

  add(command) {
    // console.log("Extensible " + Object.isExtensible(this.node));
    switch (command) {
      case "image":
        this.addImage();
        break;

      case "textVariation":
        if (_.has(this.node, "type") || _.has(this.node.content, "image")) {
          // Reset content node type back to text type when choose Text Variation type from other content node
          this.$delete(this.node, "type");

          let contentText;
          if (isArray(this.node.content)) {
            contentText = this.node.content[0].text;
          } else {
            contentText = this.node.content.text;
          }
          this.node.content = {
            text: contentText || "",
          };

          if (_.has(this.node, "listStyle")) {
            this.$delete(this.node, "listStyle");
          }

          if (this.node.options) {
            this.node.options = {
              disableTextInput: this.node.options.disableTextInput,
              feedbackCollectionMode: this.node.options.feedbackCollectionMode || "default",
            };
          }
        } else {
          this.addTextVariation();
        }

        break;

      case "improveText":
        this.improveText();
        break;

      case "quickReply":
        this.addQuickReply();
        break;

      case "trigger":
        this.addTrigger();
        break;

      case "button":
        this.addButton();
        break;

      case "card":
        this.node.type = "card";
        this.node.listStyle = "";

        if (Array.isArray(this.node.content)) {
          this.node.content.push({
            image: getDefaultPlaceholderImage(),
            text: "",
            subtext: "",
            buttons: [],
            options: {
              padded: false,
            },
          });
        } else {
          if (Array.isArray(this.node.content.text)) {
            let replaceText = "";
            this.node.content.text.forEach((text, index) => {
              if (index === 0) {
                replaceText += text;
              } else {
                replaceText += `\n${text}`;
              }
            });
            this.node.content.text = replaceText;
          }
          this.node.content = [
            {
              image: getDefaultPlaceholderImage(),
              text: "",
              subtext: "",
              buttons: [],
              options: {
                padded: false,
              },
              ...this.node.content,
            },
            {
              image: getDefaultPlaceholderImage(),
              text: "",
              subtext: "",
              buttons: [
                {
                  text: "",
                  event: "goto",
                  data: "menu",
                },
              ],
              options: {
                padded: false,
              },
            },
          ];
        }
        break;

      case "duplicate":
        this.duplicateNode();
        break;

      case "keyword":
        this.addKeyword();
        break;

      case "gifimage":
        this.node.content = {
          image: getDefaultPlaceholderImage(),
        };
        break;

      case "list":
        if (!this.node.buttons) {
          this.$set(this.node, "buttons", []);
        }
        this.node.type = "list";
        this.node.listStyle = "compact";
        if (Array.isArray(this.node.content)) {
          this.node.content.push({
            image: getDefaultPlaceholderImage(),
            text: "",
            subtext: "",
            buttons: [],
          });
        } else {
          if (Array.isArray(this.node.content.text)) {
            let replaceText = "";
            this.node.content.text.forEach((text, index) => {
              if (index === 0) {
                replaceText += text;
              } else {
                replaceText += `\n${text}`;
              }
            });
            this.node.content.text = replaceText;
          }
          this.node.content = [
            {
              image: getDefaultPlaceholderImage(),
              text: "",
              subtext: "",
              buttons: [],
              ...this.node.content,
            },
            {
              image: getDefaultPlaceholderImage(),
              text: "",
              subtext: "",
              buttons: [
                {
                  text: "",
                  event: "goto",
                  data: "menu",
                },
              ],
            },
          ];
        }
        break;

      case "grid":
        this.node.type = "grid";
        this.node.options.mode = "grid";
        this.node.content = {
          images: [getDefaultPlaceholderImage()],
          options: {
            column: 2,
          },
        };
        break;

      case "slider":
        this.node.type = "slider";
        this.node.options.mode = "slider";
        this.node.content = {
          text: "",
          buttons: [],
          sliderConfig: {
            minText: "",
            maxText: "",
            nodeEvent: {
              event: "capture",
              next: {
                data: "",
                type: "event",
                event: "goto",
              },
              key: "",
              text: "",
            },
            event: {},
            units: 3,
            mainColor: "#4e6cce",
          },
        };
        break;

      case "whatsapp_list":
        this.node.type = "whatsapp_list";
        this.node.content = {
          text: this.node.content.text || "",
          options: {
            header: "",
            footer: "",
            listHeader: "",
            msg_type: "whatsapp_list",
            sections: [
              {
                title: "",
                rows: [],
              },
            ],
          },
        };
        break;

      case "whatsapp_product_message":
        this.node.type = "whatsapp_product_message";
        this.node.content = {
          ...this.node.content,
          options: {
            msg_type: "",
          },
        };
        break;
      case "whatsapp_location_message":
        this.node.type = "whatsapp_location_message";
        this.node.content = {
          ...this.node.content,
          options: {
            msg_type: "",
          },
        };
        break;
      case "whatsapp_contact_message":
        this.node.type = "whatsapp_contact_message";
        this.node.content = {
          ...this.node.content,
          options: {
            msg_type: "whatsapp_contact_message",
            contacts: [],
          },
        };
        break;
      case "whatsapp_cta":
        this.node.type = "whatsapp_cta";
        this.node.content = {
          text: this.node.content.text || "",
          buttons: [
            {
              text: "",
              url: "",
            },
          ],
          options: {
            header: "",
            footer: "",
            msg_type: "whatsapp_cta",
          },
        };
        break;

      case "video":
        this.node.type = "video";
        this.$set(this.node, "quickReplies", []);
        this.node.content = {
          text: "",
          buttons: [],
          subtext: "",
          url: "",
          options: {
            mode: "video",
            isShowSubtitles: false,
            type: "Generate Video",
            videoName: "",
            template: {
              callbackId: `${this.id}-${this.$store.state.brain}-videos`,
              templateId: "",
              templateData: {
                video_script: "",
              },
              test: true,
              visibility: "public",
              title: "",
              description: "",
            },
          },
        };
        break;
    }
  }

  deleteNode() {
    this.$confirm("This will delete the node. Continue?", "Warning", {
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      type: "info",
    }).then(() => {
      this.deleting = true;
      let payload = {
        type: this.nodeType,
        id: this.id,
        old_values: {
          id: this.old_node_id,
          type: this.nodeType,
          node: this.old_node,
        },
      };
      return this.$store
        .dispatch("DELETE_NODE", payload)
        .then(() => {
          this.deleting = false;
          this.$message({
            type: "success",
            message: "Node deleted",
          });
          this.$store.commit("SET_REFETCH_CONTENT_NODE", true);
        })
        .catch(() => {
          this.deleting = false;
          this.$message({
            type: "error",
            message: "Failed to delete node from server",
          });
        });
    });
  }
  renameNode() {
    if (!this.isAuthorisedForChange)
      return this.$message({
        type: "error",
        message: "You're not authorised to make changes to this node",
      });

    if (!this.newBaseId) {
      return this.$message({
        type: "warning",
        message: "Base ID cannot be empty",
      });
    }

    const newId = _.compact([this.newBaseId, this.newVersionId]).join(":");
    const oldId = this.id;

    const idChanged = oldId !== newId;
    if (idChanged) {
      this.renameContentId = true;
      this.old_node_id = oldId;

      if (_.has(this.stickyMenu, oldId) || this.isStickyMenu) {
        this.$delete(this.stickyMenu, oldId);
        if (!this.saveStickyMenu(newId)) return;
      }

      this.$store.commit("RENAME_NODE", {
        type: this.nodeType,
        oldId,
        newId,
      });

      this.$store.dispatch("SELECT_NODE", { type: this.nodeType, id: newId });
      this.$nextTick(() => {
        this.old_node_id = oldId;
        this.saveChanges();

        this.$store.dispatch("DELETE_RENAMED_OLD_NODE", {
          type: this.nodeType,
          id: oldId,
        });
      });
    }

    this.editBaseId = false;
    this.editVersionId = false;
  }
  saveChanges() {
    if (this.nodeHistoryType !== "Current") {
      this.$confirm(
        "Would you like to restore the previous snapshot and overwrite the current content?",
        "Warning",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "warning",
        }
      )
        .then(() => {
          this.$message({
            type: "success",
            message: "Content replaced with previous snapshot",
          });

          this.saveContentNode();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Previous snapshot not applied",
          });
        });
    } else {
      this.saveContentNode();
    }
  }
  flagOutMakerCheckerWarning() {
    const makerCheckerEnabled = _.get(this, "$store.state.modules.makerChecker.enabled");
    const contentNodeIsEqual = _.isEqual(this.node, this.old_node);
    const IdIsEqual = _.isEqual(this.id, this.old_node_id);
    if (contentNodeIsEqual && IdIsEqual && makerCheckerEnabled && !this.isStickyMenuConfigChanged) {
      this.isStickyMenuConfigChanged = false;
      this.$notify.warning({
        title: "No Change Detected",
        message: "No change is created",
        position: "bottom-right",
      });
      return true;
    }
    this.isStickyMenuConfigChanged = false;
    return false;
  }
  saveContentNode() {
    this.saving = true;
    if (!this.saveStickyMenu(this.id)) return;

    const isWarning = this.flagOutMakerCheckerWarning();
    if (isWarning) {
      this.saving = false;
      return;
    }

    const contentButtons = this.node.content.buttons;
    if (!_.isEmpty(contentButtons)) {
      for (let i = 0; i < this.node.content.buttons.length; i++) {
        delete this.node.content.buttons[i].active;
      }
    }
    this.formatVideoContentNode(this.node);

    // Clear un used value in node
    this.clearNodeUnUsedValue(this.node.type);

    this.$store
      .dispatch("EDIT_NODE", {
        type: this.nodeType,
        id: this.id,
        node: this.node,
        old_values: {
          id: this.old_node_id,
          type: this.nodeType,
          node: this.old_node,
        },
        new_values: {
          type: this.nodeType,
          id: this.id,
          node: this.node,
        },
        isRename: this.renameContentId,
      })
      .then(() => {
        this.$store.commit("SET_CHANGE_CONTENT_NODE", true);
        this.saving = false;
        this.$message({
          type: "success",
          message: "Content Saved",
        });

        // updating node to be old_node after save

        this.$nextTick(function () {
          const theNewOld = _.cloneDeep(this.node);
          this.old_node = theNewOld;
        });
      })
      .catch((err) => {
        this.saving = false;
        this.$message({
          type: "error",
          message: this.graphQLError(err, "Error while saving content"),
        });
      })
      .then(() => {
        this.renameContentId = false;
      });
  }
  addImage() {
    if (!this.node.content.image) {
      this.$set(this.node.content, "image", "");
    }

    this.node.content.image = getDefaultPlaceholderImage();
  }
  addVersion() {
    if (!this.newVersionId) {
      return this.$message({
        type: "warning",
        message: "Version ID cannot be empty",
      });
    }

    const newId = this.baseId + ":" + this.newVersionId;
    let newNode = _.cloneDeep(this.node);

    this.$store.commit("CREATE_CONTENT_NODE", {
      id: newId,
      node: newNode,
    });

    this.$message({
      type: "success",
      message: `Created new version`,
    });

    this.$emit("select", { type: "content", id: newId });

    this.newVersionId = "";
    this.showNewVersionPopover = false;
  }
  addTextVariation() {
    if (!Array.isArray(this.node.content)) {
      if (Array.isArray(this.node.content.text)) {
        this.node.content.text.push("");
      } else {
        this.node.content.text = [this.node.content.text, ""];
      }
    } else {
      console.warn("Cannot add variation");
    }
  }
  addButton() {
    if (Array.isArray(this.node.content)) {
      let index = this.selectedCardIndex || 0;
      let card = this.node.content[index];
      if (!card.buttons) {
        this.$set(card, "buttons", []);
      }

      card.buttons.push({
        text: "",
        type: "event",
        active: "event",
        event: null,
        data: null,
        url: null,
        phone: null,
        webview_height_ratio: null,
      });
    } else {
      if (!this.node.content.buttons) {
        this.$set(this.node.content, "buttons", []);
      }

      this.node.content.buttons.push({
        text: "",
        type: "event",
        active: "event",
        event: null,
        data: null,
        url: null,
        phone: null,
        webview_height_ratio: null,
      });
    }
  }
  addQuickReply() {
    if (!this.node.quickReplies) {
      this.$set(this.node, "quickReplies", []);
    }

    this.node.quickReplies.push({
      text: "Quick Reply",
      event: null,
      data: null,
    });
  }
  addTrigger() {
    this.$store.dispatch("CREATE_TRIGGER_NODE", {
      id: this.id,
      gotoTarget: this.id,
    });
    this.$store.state.activeNodeType = "trigger";
  }
  addNext() {
    this.$set(this.node, "next", {
      type: "event",
      event: null,
      data: null,
    });
  }
  removeNext() {
    this.$delete(this.node, "next");
  }
  addDelay() {
    this.$set(this.node, "delay", 1);
  }
  removeDelay() {
    this.$delete(this.node, "delay");
  }
  duplicateNode() {
    let newName = this.id + "_copy";

    this.$message({
      message: `Created ${newName}`,
    });

    this.$store.commit("CREATE_CONTENT_NODE", {
      id: newName,
      node: _.cloneDeep(this.node),
    });
    this.$emit("select", { type: "content", id: newName });
  }
  getEventOptions() {
    let options = {};
    this.$store.state.nodes.event.forEach(function (el) {
      options[el] = el;
    });
    return options;
  }
  createContentNode() {
    if (!isValidContentName(this.tempNode.id))
      return this.$message({
        type: "error",
        message: `Invalid node name`,
      });

    this.$store.commit("CREATE_CONTENT_NODE", { id: this.tempNode.id });
    this.id = this.tempNode.id;
    this.tempNode.id = "";
    this.$emit("select", { type: "content", id: this.tempNode.id });
    this.selectNode(this.id);
  }
  addCondition() {
    if (!this.node.conditions) {
      this.$set(this.node, "conditions", []);
    }
    this.node.conditions.push({
      property: "",
      value: "",
      operator: "",
      valueType: "JSON",
    });
  }
  selectVersion(version) {
    this.nodeHistoryType = version;
    const found = _.find(this.changes[this.id], { snapshotId: version });
    if (found) {
      this.node = _.cloneDeep(found.content);
      this.componentKey++;
    }
  }
  addKeyword() {
    if (!this.node.keywords) {
      this.$set(this.node, "keywords", []);
    }

    this.editKeyword = true;
  }
  deleteKeyword(index) {
    this.node.keywords.splice(index, 1);
    this.editKeyword = false;
    this.newKeyword = {};
  }
  newKeywordConfirm(confirmedKeyword) {
    const allKeywords = this.$store.state.nodes.keywords;

    if (!confirmedKeyword) {
      this.newKeyword = {};
      this.editKeyword = false;
    }

    if (confirmedKeyword.value) {
      if (
        (_.get(this, "node.keywords") &&
          _.find(this.node.keywords, { value: confirmedKeyword.value })) ||
        allKeywords[confirmedKeyword.value]
      ) {
        this.$message.error(
          `Please type unique keyword only. Same keyword has been applied to [${
            allKeywords[confirmedKeyword.value]
          }]`
        );
        this.newKeyword = {};
        return;
      }

      confirmedKeyword.value = confirmedKeyword.value.toLowerCase();
      confirmedKeyword.edit = false;
      this.node.keywords = [...this.node.keywords, confirmedKeyword];
      this.newKeyword = {};
      this.editKeyword = false;
    }
  }
  clearNodeUnUsedValue(nodeType) {
    if (nodeType !== "slider" && nodeType !== "grid") {
      delete this.node.options?.mode;
    }

    if (nodeType !== "slider") {
      if (_.isArray(this.node.content)) {
        delete this.node.content[0].sliderConfig;
      } else {
        delete this.node.content.sliderConfig;
      }
    }

    if (nodeType !== "grid") {
      if (_.isArray(this.node.content)) {
        delete this.node.content[0].options?.column;
      } else {
        delete this.node.content.options?.column;
      }
    }
  }
  formatVideoContentNode(node) {
    if (node.type !== "video") {
      if (Array.isArray(node.content)) {
        delete node.content[0].options?.template;
        delete node.content[0].options?.mode;
        delete node.content[0].options?.isShowSubtitles;
        delete node.content[0].options?.videoName;
        delete node.content[0].options?.type;
      } else {
        delete node.content.options?.template;
        delete node.content.options?.mode;
        delete node.content.options?.isShowSubtitles;
        delete node.content.options?.videoName;
        delete node.content.options?.type;
      }
    }
  }
  async improveText() {
    this.textImprovement = true;
    this.improvingText = true;
    this.currentText = "";
    this.textChoices = [];
    if (Array.isArray(this.node.content.text)) {
      this.currentText = this.node.content.text[0];
    } else {
      this.currentText = this.node.content.text;
    }

    const payload = {
      text: this.currentText,
      instruction: "Fix grammar and paraphrase the text",
      no_of_edits: 2,
      temperature: 0.7,
    };

    try {
      const choices = await this.$rest("post", "openai_text_edit", payload);
      this.textChoices = choices;
    } catch (error) {
      this.textChoices = [];
      console.log(error);
    }

    this.improvingText = false;
  }
  chooseText(idx) {
    this.$confirm("Change current content with this answer?", "Warning", {
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      type: "warning",
    })
      .then(() => {
        this.node.content.text = this.textChoices[idx].text;
        this.textImprovement = false;
      })
      .catch(() => {
        // cancel
      });
  }
}
</script>

<style lang="scss">
@import "../assets/scss/colors.scss";

.nodeId {
  cursor: pointer;
  line-height: 36px;
  word-break: break-all;
}

.nodeId:hover {
  color: deepskyblue;
}

.keyreply-conditions-row button {
  opacity: 0;
  margin-left: 5px;
}
.keyreply-conditions-row:hover button {
  opacity: 1;
}

.edge-settings {
  width: 150px;
  margin: auto;
  text-align: center;

  .delay-slider {
    height: 30px;
  }

  .button-remove-delay {
    padding-top: 0%;
  }

  .button-remove-link {
    padding-bottom: 0%;
  }
}

.node-settings-block {
  width: 100%;
  text-align: center;
  margin-top: 10px;

  .node-settings {
    display: inline-block;
    text-align: left;
  }
}

.el-card__header .versionTag,
.el-card__header span .el-input {
  position: relative;
  bottom: 3px;
}

.el-card__header .keywordTag {
  margin-right: 3px;
}

.image-media {
  text-align: center;
}
.value-type .el-input__inner {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.value-field .el-input__inner,
.value-field .el-textarea__inner {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.el-select.value-type .el-input.is-focus .el-input__inner {
  border-color: $color-light;
}
.el-select.value-type:hover .el-input__inner {
  border-color: $color-light;
}
.el-select.value-type .el-input__inner:focus {
  border-color: $color-light;
}

.duplicate {
  margin: 0 0 20px 0;
  color: $color-danger;
}
.duplicate strong {
  cursor: pointer;
}
.sticky-input {
  max-width: 50px;
  height: 25px;
}

.sticky-input input {
  width: 100%;
  height: 100%;
  padding: 2px 5px;
}

.answer-bubble-text {
  margin-bottom: 20px;
  padding: 8px 10px;
  box-sizing: border-box;
  border-radius: 5px;
  border: lightgray;
  background-color: lightgray;
}
</style>
